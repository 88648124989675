<template>
  <div class="card">
    <h3 class="title">
      <slot name="title"></slot>
    </h3>
    <div class="desc">
      <slot name="description"></slot>
    </div>
    <NuxtLink
      :href="href"
      no-prefetch
      target="_blank"
      @click="handleClick(destination)"
    >
      <slot name="button"></slot>
    </NuxtLink>
  </div>
</template>

<script setup>
defineProps({
  destination: {
    required: true,
  },
  href: {
    type: String,
    required: true,
  },
})

const handleClick = (destination) => useTracking().track('[HP] Clic CTA', { destination })
</script>

<style lang="less" scoped>
.card {
  padding: 32px 46px;
  background-color: var(--ds-color-white-100);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &.no-background {
    background-color: transparent;
  }

  &.no-padding {
    padding: 0;
  }

  .title {
    font-size: 26px;
    font-weight: var(--ds-weight-semi-bold);
    color: var(--ds-color-gray-100);
    margin: 0;
  }

  .desc {
    font-size: 18px;
    color: var(--ds-color-gray-50);
  }

  .actions {
    padding-top: 12px;
  }
}

@media @bp-md-down {
  .card {
    padding: 20px;

    .title {
      font-size: 22px;
    }

    .desc {
      font-size: var(--ds-font-sm);
    }

    .actions button {
      width: 100%;
    }
  }
}
</style>
